<template>
  <div class="faq-wrapper">
    <div class="faq-title">
      <h2>
        More about Human-Like
        <span style="color: var(--clr-accent)">Chess Games</span>
      </h2>
    </div>
    <div class="faq-body">
      <div
        class="faq-section"
        v-for="(faqsection, index) of faqs"
        :key="'faq-content' + index"
      >
        <h4>{{ faqsection.section }}</h4>
        <div
          class="card faq-qa ph-no-capture"
          v-for="(qa, qaindex) of faqsection.qas"
          :key="'faq-qa' + qaindex"
          @click="
            () => {
              selectQa(qa.id);
              track('faq', 'load_more_games', 'click', { selectedQa: qa.id });
            }
          "
          role="button"
        >
          <div class="qa-header">
            <h4>{{ qa.question }}</h4>
            <i v-if="qa.selected" class="fa-solid fa-caret-up" />
            <i v-else class="fa-solid fa-caret-down" />
          </div>
          <Transition name="bounce">
            <p v-if="qa.selected">{{ qa.answer }}</p>
          </Transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { track } from "@/util/tracking";

function selectQa(qaId: number) {
  for (let faqsection of faqs.value) {
    for (let qa of faqsection.qas) {
      if (qa.id === qaId) {
        qa.selected = !qa.selected;
      } else {
        qa.selected = false;
      }
    }
  }
}

let faqs = ref<any>([
  {
    section: "About Chessiverse",
    qas: [
      {
        id: 1,
        selected: false,
        question: "What is Chessiverse?",
        answer:
          "Chessiverse is an immersive online chess platform that combines traditional chess gameplay with the latest advancements in AI. Here, you can play chess against sophisticated opponents, join weekly challenges, practice against your perfect opponent and much more.",
      },
      {
        id: 2,
        selected: false,
        question:
          "What makes Chessiverse different from other online chess platforms?",
        answer:
          "Chessiverse stands out with its combination of human-like bots, accurate rating systems, and engaging AI personalities. Our platform offers a unique chess experience that is both challenging and enjoyable, whether you’re a beginner or an experienced player.",
      },
      {
        id: 3,
        selected: false,
        question: "How do I start playing in Chessiverse?",
        answer:
          "To begin your Chessiverse adventure, simply sign up for an account on our website. Once registered, you can immediately start playing games, participate in challenges, and get to know our quirky characters.",
      },
    ],
  },
  {
    section: "The Chessiverse Bots",
    qas: [
      {
        id: 4,
        selected: false,
        question: "Are there different levels of difficulty?",
        answer:
          "Yes! Chessiverse caters to players of all skill levels, from beginners to advanced. You can choose opponents and challenges that match your current chess proficiency.",
      },
      {
        id: 5,
        selected: false,
        question: "What makes the bots in Chessiverse unique?",
        answer:
          "Chessiverse boasts hundreds of unique bots, each with its distinct play style. Unlike traditional chess AI, our bots are designed to emulate human-like gameplay, offering a more realistic and engaging chess experience.",
      },
      {
        id: 6,
        selected: false,
        question: "How are the engines in Chessiverse created?",
        answer:
          "The Chessiverse bots uses the same technology as Leela Chess Zero. That is trained neural networks without a conventional search tree. This makes the bots play more like humans, with imperfections and strength.",
      },
      {
        id: 328,
        selected: false,
        question: "How do you create the openings books for the bots?",
        answer:
          "Each bot has its own unique opening repertoire! The repertoires are created using human games, and choosing the most likely moves a human would play in the opening at that level. We also steer the openings towards interesting positions that suites the bot's playstyle. This make every game completely unique, but still familiar!",
      },
      {
        id: 7,
        selected: false,
        question: "How accurate are the ratings in Chessiverse?",
        answer:
          "We aim to keep the ratings as close to FIDE as possible. This means that the online ratings you might be used to can feel a bit high. If you beat a 1500 rated bot in Chessiverse, you should stand a good chance at a 1500 FIDE rated human opponent as well.",
      },
      {
        id: 8,
        selected: false,
        question:
          "Can you tell me more about the bots’ personalities in Chessiverse?",
        answer:
          "Our bots come with authentic personalities, adding an extra dimension to your games. Thanks to recent AI developments, they offer interesting facts and engaging stories, providing a friendly and non-toxic chess environment.",
      },
    ],
  },

  {
    section: "Playing on Chessiverse",
    qas: [
      {
        id: 9,
        selected: false,
        question: "What types of games can I play?",
        answer:
          "Right now you can challenge the bots to casual games as much as you like, or join our challenges or pick up a practice position. More game modes are being added, keep a look out for them in the near future!",
      },
      {
        id: 10,
        selected: false,
        question: "Can you tell me more about Challenges?",
        answer:
          "Chessiverse should feel alive so we are constantly adding new challenges. Right now we have the weekly challenge where you can play against our bots in different scenarios. And many more things like that are coming up, keep an eye on your dashboard!",
      },
      {
        id: 11,
        selected: false,
        question: "Why should I use Chessiverse to practice?",
        answer:
          "The Chessiverse practice philosophy is that the best way to learn is to play. But finding a training partner of perfect level that always have time for you is just impossible. The Chessiverse bots are always ready to play, and you will always be able to find a bot of the perfect level and playstyle as you grow.",
      },
      {
        id: 12,
        selected: false,
        question:
          "Are there any specific ways to practice other than playing games?",
        answer:
          "Yes! We have a whole section of practice positions prepared, where you can practice openings, middlegames, endgames or learn from master games. You can track your progress and choose the perfect bot to train against. ",
      },
      {
        id: 13,
        selected: false,
        question: "Can I create my on practice positions?",
        answer:
          "Absolutely, you can set up whatever position you like and choose a bot to train against!",
      },
    ],
  },
  {
    section: "Other questions",
    qas: [
      {
        id: 15,
        selected: false,
        question: "What does it cost?",
        answer:
          "You can try out Chessiverse completely free, if you like it there is a Premium tier that unlocks all the functionality",
      },
      {
        id: 16,
        selected: false,
        question: "What if I run into problems?",
        answer:
          "At the bottom of this page you can find links to our Contact page and our Discord server. Please reach out to us if you have any questions or problems. We are happy to help!",
      },
    ],
  },
]);
</script>

<style scoped>
.bounce-enter-active {
  transform-origin: top;
  animation: bounce-in 0.5s;
}
@keyframes bounce-in {
  0% {
    transform: scaleY(0);
  }
  50% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(1);
  }
}
.profile-selector-container {
  cursor: pointer;
}
h2,
h3,
h4 {
  font-size: 2.2rem;
  text-align: center;
}

.faq-body h3 {
  font-size: 2rem;
}

.faq-body h4 {
  font-size: 1.2rem;
  text-align: left;
}

.faq-title {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.faq-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin-top: 1rem;
}

.faq-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
}

.faq-qa {
  padding: 1rem;
  width: 40rem;
}

@media (max-width: 768px) {
  .faq-qa {
    width: 90%;
  }
}

.qa-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  color: var(--clr-accent);
}

.faq-qa p {
  color: var(--clr-main-light);
}

.qa-header i {
  margin-top: -0.5rem;
  color: var(--clr-main-lighter);
  font-size: 1.5rem;
}
</style>

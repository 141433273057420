import { defineStore } from "pinia";
import type { Bot, ChallengeFromPosition, User } from "@/types/apitypes";
import type { User as FirebaseUser, UserCredential } from "@firebase/auth";
import { auth } from "@/firebase";
import * as api from "@/services/rest";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import type { Router } from "vue-router";
import { parseDate } from "@/util/util";
import { updatePremiumState } from "@/util/premium";

export const useUserStore = defineStore("user", {
  state: () => ({
    user: {
      loggedIn: false,
      data: null as User | null,
      firebaseUser: null as FirebaseUser | null,
      idToken: null as string | null,
      lastRefresh: null as number | null, // Time since epoch in milliseconds
    },
    router: null as Router | null, // Use for navigation, for example after logout
  }),
  actions: {
    setRouter(router: Router) {
      // @ts-ignore Not sure why TS is compaining about this, the objects are the same type as far as I can see
      this.router = router;
    },
    async reloadFirebaseUser(): Promise<void> {
      // If reload is not a function on the firebaseUser it means that the persistence serialisation removed it, so need to initialise it again
      if (typeof this.user.firebaseUser?.reload !== "function") {
        this.user.firebaseUser = await new Promise(
          (resolve: any, reject: any) =>
            auth.onAuthStateChanged(
              (user: any) => resolve(user),
              (e: any) => reject(e)
            )
        );
      }

      await this.user.firebaseUser!.reload();
    },
    async getFirstunbeatenBot(): Promise<Bot | null> {
      return (await api.getUnbeatenBots()).data.first;
    },
    async getNextunbeatenBot(): Promise<Bot | null> {
      return (await api.getUnbeatenBots()).data.next;
    },
    async getUnbeateBots(): Promise<{
      next: Bot | null;
      first: Bot | null;
    }> {
      return (await api.getUnbeatenBots()).data;
    },
    async getNextUnfinishedPuzzleSet(currentPuzzleSetId: string): Promise<{
      first?: Bot;
      next?: Bot;
    } | null> {
      return (await api.getNextUnfinishedPuzzleSet(currentPuzzleSetId)).data;
    },
    async getNextUnbeatenChallenge(): Promise<{
      challenge: ChallengeFromPosition;
      unbeatenDifficulty: string;
    } | null> {
      return (await api.getNextUnbeatenChallenge()).data;
    },
    async refreshIdToken(force: boolean = false): Promise<string | null> {
      if (this.user.firebaseUser == null) {
        return null;
      }

      function has55MinutesPassed(since: number | null) {
        if (since == null) {
          return true;
        }
        const currentTime = new Date();
        const timeDifferenceInMilliseconds = currentTime.getTime() - since;
        const timeDifferenceInMinutes =
          timeDifferenceInMilliseconds / (1000 * 60); // Convert milliseconds to minutes
        return timeDifferenceInMinutes >= 55;
      }

      if (
        !force &&
        this.user.idToken != null &&
        !has55MinutesPassed(this.user.lastRefresh)
      ) {
        return this.user.idToken;
      }

      this.user.lastRefresh = new Date().getTime();

      // If getIdToken is not a function on the firebaseUser it means that the persistence serialisation removed it, so need to initialise it again
      if (typeof this.user.firebaseUser?.getIdToken !== "function") {
        this.user.firebaseUser = await new Promise(
          (resolve: any, reject: any) =>
            auth.onAuthStateChanged(
              (user: any) => resolve(user),
              (e: any) => reject(e)
            )
        );
      }

      this.user.idToken = await this.user.firebaseUser!.getIdToken(true);
      if (
        this.user.firebaseUser?.uid != null &&
        this.user.firebaseUser?.email != null
      ) {
        // If we have the data do identification against. Unclear how often we should do this, but posthog says once per session so this seems like agood place
        posthog.identify(this.user.firebaseUser?.uid, {
          email: this.user.firebaseUser?.email,
          signed_up_at:
            parseDate(
              this.user.firebaseUser?.metadata.creationTime
            )?.toISOString() ?? "",
        });
      }
      return this.user.idToken;
    },
    async setInitialRating(
      initialRating: "beginner" | "intermediate" | "advanced"
    ) {
      await api.setInitialRating(initialRating);
    },
    async refreshUserProfile() {
      const userProfile = await api.getUserProfile();
      this.user.data = userProfile.data.user;

      updatePremiumState(userProfile.data.user);

      return userProfile.data.user;
    },
    async getStats(userId: string | undefined = undefined) {
      return (await api.getStats(userId)).data;
    },
    async getQuickStats(userId: string | undefined = undefined) {
      const quickStats = await api.getQuickStats(userId);
      return quickStats.data;
    },
    async finalizeSignin(userCredential: UserCredential): Promise<boolean> {
      // Return true if the user was created now, false if they were already created

      if (userCredential.user == null) {
        // Something wrong here so just return false
        return false;
      }

      const idToken = await userCredential.user.getIdToken(true);
      const userProfile = await api.getInitialUserProfile(
        userCredential.user.uid,
        idToken
      );
      posthog.identify(userCredential.user.uid, {
        email: userCredential.user.email ?? "no-email",
        signed_up_at:
          parseDate(userCredential.user.metadata.creationTime)?.toISOString() ??
          "",
      });

      this.user = {
        loggedIn: true,
        data: userProfile.data.user,
        firebaseUser: userCredential.user,
        lastRefresh: new Date().getTime(),
        idToken: idToken,
      };

      updatePremiumState(userProfile.data.user);

      return userProfile.data.new;
    },
    async signin(email: string, password: string) {
      let userCredential;
      try {
        userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
      } catch (error) {
        return Promise.reject(error);
      }

      await this.finalizeSignin(userCredential);
    },
    async signup(email: string, password: string, newsletterConsent: boolean) {
      try {
        await createUserWithEmailAndPassword(auth, email, password);
      } catch (error) {
        return Promise.reject(error);
      }

      try {
        await this.signin(email, password);
        if (newsletterConsent) {
          // Fire and forget, we won't be handling if this fails in the frontend anyway, it more important that the user gets logged in
          // and ready to play that trying to retrieve the newsletter consent if it in very rare circumstances fails
          api.setNewsletterConsentAccepted();
        }

        // Not await this, just fire and forget, if the user didn't receive the mail they can retrigger it
        this.triggerVerificationEmail();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async triggerVerificationEmail(): Promise<void> {
      try {
        await sendEmailVerification(auth.currentUser!);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async resetPassword(email: string) {
      try {
        await sendPasswordResetEmail(auth, email);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async logout() {
      await signOut(auth);

      posthog.reset();

      this.user = {
        loggedIn: false,
        data: null,
        firebaseUser: null,
        lastRefresh: null,
        idToken: null,
      };

      updatePremiumState(null);

      // TODO Remember to clear everything here if new stores are added (so Pinia adds them to local storage), or if we add something manual in the future
      localStorage.removeItem("page");
      localStorage.removeItem("user");
      localStorage.removeItem("game");
      localStorage.removeItem("bots");

      window.location.href = window.location.origin;
    },
  },
  getters: {
    getUserCreationTime(): Date {
      let timestamp =
        useUserStore().user.firebaseUser?.metadata?.creationTime ?? null;

      let date;

      if (timestamp == null) {
        // Fallback to createdAt if creationTime is not available, can probably remove this, but was needed for one old user
        // @ts-ignore createdAt is probably old so not in the types
        timestamp = useUserStore().user.firebaseUser?.createdAt ?? null;
        if (timestamp == null) {
          return new Date(0); // Instead of not returning anything, return the epoch date
        }

        // If it comes from createdAt it's a timestamp in string form..
        date = new Date(parseInt(timestamp, 10));
      } else {
        // If it comes from creationTime it's a string
        date = new Date(timestamp);
      }

      return date;
    },
  },
  persist: true,
});

<template>
  <div
    class="card"
    style="
      display: flex;
      flex-direction: column;
      width: 19.5rem;
      padding: 1rem 1rem 0 1rem;
      filter: drop-shadow(2px 2px 2px var(--clr-main-lighter));
    "
  >
    <div style="display: flex; flex-direction: column; align-items: center">
      <h3>Most played</h3>
      <span style="margin-top: -0.5rem">(games played)</span>
    </div>
    <div style="display: flex; justify-content: center; gap: 0.2rem">
      <div
        v-for="(bot, index) in bots"
        :key="'favbot-' + bot.botId"
        style="display: flex; flex-direction: column; align-items: center"
      >
        <div
          :style="{
            height: 100 * (1 - getPercentage(index)) + 'px',
            width: '1rem',
            backgroundColor: '#fbfbfb',
          }"
        ></div>
        <div
          :style="{
            height: 100 * getPercentage(index) + 'px',
            width: '1rem',
            backgroundColor: 'var(--clr-accent)',
          }"
        ></div>
        <div>{{ loading ? "-" : bot.total }}</div>
        <ProfileCircleWithPlaque
          v-if="!loading"
          :bot="useBotsStore().getBot(bot.botId)"
          :show-plaque="false"
          :imageSize="3.5"
          border-color="#333"
          style="margin-bottom: 1.5rem"
          role="button"
          @click="
            () => {
              useModalsStore().showBotProfile(bot.botId);
            }
          "
        />
        <div
          v-else
          style="
            width: 3.5rem;
            height: 3.5rem;
            margin-bottom: 1.5rem;
            border-radius: 50%;
            border: 2px solid var(--clr-main-lightest);
          "
        ></div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import ProfileCircleWithPlaque from "@/components/common/ProfileCircleWithPlaque.vue";
import { useBotsStore } from "@/stores/botStore";
import { useModalsStore } from "@/stores/modalsStore";
import { type PropType, ref } from "vue";

const props = defineProps({
  bots: {
    type: Array as PropType<
      {
        botId: string;
        total: number;
        wins: number;
        draws: number;
        losses: number;
      }[]
    >,
    required: true,
  },
  loading: {
    type: Boolean,
    default: true,
  },
});

let showWins = ref(false);

function getPercentage(index: number) {
  if (props.loading) return 0;

  const total = props.bots
    .map((bot) => bot.total)
    .reduce((sum, value) => sum + value, 0);

  return props.bots[index].total / total;
}
</script>

<template>
  <div
    class="card"
    style="
      display: flex;
      flex-direction: column;
      width: 19.5rem;
      padding: 1rem 1rem 0 1rem;
      filter: drop-shadow(2px 2px 2px var(--clr-main-lighter));
    "
  >
    <div style="display: flex; flex-direction: column; align-items: center">
      <h3>Playstyles</h3>
      <span style="margin-top: -0.5rem">(games played)</span>
    </div>
    <div style="display: flex; justify-content: center; gap: 0.2rem">
      <div
        v-for="(p, index) in games"
        :key="'playstyle-' + index"
        style="display: flex; flex-direction: column; align-items: center"
      >
        <div
          :style="{
            height: 100 * (1 - getPercentage(p.total)) + 'px',
            width: '1rem',
            backgroundColor: '#fbfbfb',
          }"
        ></div>
        <div
          :style="{
            height: 100 * getPercentage(p.total) + 'px',
            width: '1rem',
            backgroundColor: 'var(--clr-accent)',
          }"
        ></div>
        <div>{{ loading ? "-" : p.total }}</div>
        <img
          :style="{
            width: '3.5rem',
            height: '3.5rem',
            marginBottom: '1.5rem',
            filter: loading ? 'saturate(0%) opacity(20%)' : '',
          }"
          :src="p.playstyle.img"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { type PropType, ref } from "vue";
import { personaCategories } from "@/util/personplaycategory";

const props = defineProps({
  games: {
    type: Array as PropType<
      {
        playstyle: any;
        total: number;
        wins: number;
        draws: number;
        losses: number;
      }[]
    >,
    required: true,
  },
  loading: {
    type: Boolean,
    default: true,
  },
});

let showWins = ref(false);

function getPercentage(thisGames: number) {
  if (props.loading) return 0;

  const total = props.games
    .map((bot) => bot.total)
    .reduce((sum, value) => sum + value, 0);

  return thisGames / total;
}
</script>

<template>
  <div style="display: flex; flex-direction: column; align-items: center">
    <div style="display: flex; align-items: center">
      <div
        v-for="(playstyle, i) in playstyles"
        :key="'playstyle' + i"
        style="display: flex; flex-direction: column; align-items: center"
        v-tippy="{
          content: playstyle.tooltip,
        }"
      >
        <img
          :src="playstyle.img"
          :class="selected == i + 1 ? 'selected' : 'normal'"
        />
        <span
          v-if="selected == i + 1"
          style="font-weight: 700; font-size: 1.1rem"
          >{{ playstyle.name }}</span
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { personaCategories } from "@/util/personplaycategory";

const props = defineProps({
  selected: { type: Number, required: true },
});

const playstyles = personaCategories();
</script>

<style scoped>
.selected {
  height: 4rem;
  width: 4rem;
}

.normal {
  height: 1rem;
  width: 1rem;
  filter: saturate(30%) opacity(50%);
}
</style>

import { defineStore } from "pinia";
import * as api from "@/services/rest";
import { useToast } from "vue-toast-notification";
import type { Move } from "chess.js";
import type {
  ChallengeFromPosition,
  DetailedFeedback,
  Game,
  GetActiveGamesResponse,
  GetGameStateResponse,
  NewGameResponse,
  Side,
  TimeControl,
} from "@/types/apitypes";
import { usePageStore } from "@/stores/pageStore";

export const useGameStore = defineStore("game", {
  state: () => ({
    games: {} as { [key: string]: Game },
    activeGame: {
      gameId: undefined as string | null | undefined, // Either there is an id of an active game, or there isn't (null), or we haven't checked (undefined)
      refreshing: null as Promise<Game | null> | null, // Promise that resolves when the active game is loaded
    },
  }),
  actions: {
    async sendFeedback(gameId: string, feedback: DetailedFeedback) {
      await api.sendGameFeedback(gameId, feedback);
    },
    async refreshActiveGame(): Promise<Game | null> {
      if (this.activeGame.gameId !== undefined) {
        return this.activeGame.gameId === null
          ? null
          : this.games[this.activeGame.gameId];
      }

      if (this.activeGame.refreshing) {
        await this.activeGame.refreshing;

        if (this.activeGame.gameId === undefined) {
          console.error("Game id is undefined after refreshing active game");
          return Promise.reject(
            "Game id is undefined after refreshing active game"
          );
        }

        return this.activeGame.gameId === null
          ? null
          : this.games[this.activeGame.gameId];
      }

      this.activeGame.refreshing = (async () => {
        try {
          const r: GetActiveGamesResponse = await api.getActiveUserGames();

          if (r.error) {
            console.error("Failed to retrieve active games: " + r.message);
            return Promise.reject("Failed to get active games: " + r.message);
          }

          r.data.games.forEach((game) => {
            this.games[game.id] = game;
          });

          if (r.data.games.length > 0) {
            this.activeGame.gameId = r.data.games[0].id;
          } else {
            this.activeGame.gameId = null;
          }

          return r.data.games[0];
        } finally {
          // Once the request is complete, clear the promise so a new request can be made in the future
          this.activeGame.refreshing = null;
        }
      })();

      await this.activeGame.refreshing;

      if (this.activeGame.gameId === undefined) {
        console.error("Game id is undefined after refreshing active game");
        return Promise.reject(
          "Game id is undefined after refreshing active game"
        );
      }

      return this.activeGame.gameId === null
        ? null
        : this.games[this.activeGame.gameId];
    },
    async getGames(fromDate: Date, count: number): Promise<Game[]> {
      return (await api.getAllUserGames(fromDate, count)).data.games;
    },
    async refreshGame(gameId: string): Promise<Game> {
      const r: GetGameStateResponse = await api.getGameState(gameId);
      if (!r.error) {
        this.games[gameId] = r.data.game;
      }
      this.games[gameId] = r.data.game;
      return r.data.game;
    },
    async newDailyMatchupGame(): Promise<Game> {
      const r: NewGameResponse = await api.newDailyMatchupGame(
        usePageStore().gameSettings?.timeControl
      );
      if (r.error) {
        if (r.status == 409) {
          useToast().success(
            "There's already an ongoing game, finish that first!"
          );
          return Promise.reject("There's already an ongoing game");
        } else {
          useToast().error("Couldn't create new game, try again in a while");
          return Promise.reject("Couldn't create new game: " + r.message);
        }
      } else {
        this.games[r.data.game.id] = r.data.game;
        return r.data.game;
      }
    },
    async newDailyPositionGame(
      type: "dailyendgame" | "dailymaster",
      difficulty: "simple" | "easy" | "balanced" | "hard" | "intense"
    ): Promise<Game> {
      const r: NewGameResponse = await api.newDailyPositionGame(
        type,
        difficulty,
        usePageStore().gameSettings?.timeControl
      );
      if (r.error) {
        if (r.status == 409) {
          useToast().success(
            "There's already an ongoing game, finish that first!"
          );
          return Promise.reject("There's already an ongoing game");
        } else {
          useToast().error("Couldn't create new game, try again in a while");
          return Promise.reject("Couldn't create new game: " + r.message);
        }
      } else {
        this.games[r.data.game.id] = r.data.game;
        return r.data.game;
      }
    },
    async newChallengeGame(
      challengeId: string,
      difficulty: string
    ): Promise<Game> {
      const r: NewGameResponse = await api.newChallengeGame(
        challengeId,
        difficulty,
        usePageStore().gameSettings?.timeControl
      );
      if (r.error) {
        if (r.status == 409) {
          useToast().success(
            "There's already an ongoing game, finish that first!"
          );
          return Promise.reject("There's already an ongoing game");
        } else {
          useToast().error("Couldn't create new game, try again in a while");
          return Promise.reject("Couldn't create new game: " + r.message);
        }
      } else {
        this.games[r.data.game.id] = r.data.game;
        return r.data.game;
      }
    },
    async newPracticeGame(challengeId: string, botId: string): Promise<Game> {
      const r: NewGameResponse = await api.newPracticeGame(
        challengeId,
        botId,
        usePageStore().gameSettings?.timeControl
      );
      if (r.error) {
        if (r.status == 409) {
          useToast().success(
            "There's already an ongoing game, finish that first!"
          );
          return Promise.reject("There's already an ongoing game");
        } else {
          useToast().error("Couldn't create new game, try again in a while");
          return Promise.reject("Couldn't create new game: " + r.message);
        }
      } else {
        this.games[r.data.game.id] = r.data.game;
        return r.data.game;
      }
    },
    async newCustomChallengeGame(
      botId: string,
      startPosition: string,
      userSide: string
    ): Promise<Game> {
      const challenge: ChallengeFromPosition = {
        id: "custom",
        type: "from_position",
        customBot: {
          botId: botId,
        },
        start_position: startPosition,
        user_side: userSide,
      };

      const r: NewGameResponse = await api.newCustomChallengeGame(
        challenge,
        usePageStore().gameSettings?.timeControl
      );
      if (r.error) {
        if (r.status == 409) {
          useToast().success(
            "There's already an ongoing game, finish that first!"
          );
          return Promise.reject("There's already an ongoing game");
        } else {
          useToast().error("Couldn't create new game, try again in a while");
          return Promise.reject("Couldn't create new game: " + r.message);
        }
      } else {
        this.games[r.data.game.id] = r.data.game;
        return r.data.game;
      }
    },
    async newGameRated(
      botId: string | undefined,
      timeControl: TimeControl | null | undefined
    ): Promise<Game> {
      let r: NewGameResponse;

      try {
        r = await api.newGameRated(botId, timeControl);
      } catch (e) {
        useToast().error("Couldn't create new game, try again in a while");
        return Promise.reject("Couldn't create new game: " + e);
      }
      if (r.error) {
        if (r.status == 409) {
          useToast().success(
            "There's already an ongoing game, finish that first!"
          );
          return Promise.reject("There's already an ongoing game");
        } else {
          if (r.message != "User does not have an initial rating") {
            // Only toast if it's not the "no initial rating" error, since that will be handled by the UI
            useToast().error("Couldn't create new game, try again in a while");
          }

          return Promise.reject("Couldn't create new game: " + r.message);
        }
      } else {
        this.games[r.data.game.id] = r.data.game;
        return r.data.game;
      }
    },
    async newGameCasual(
      botId: string,
      side: Side,
      timeControl: TimeControl | null | undefined
    ): Promise<Game> {
      let r: NewGameResponse;

      try {
        r = await api.newGameCasual(botId, side, timeControl);
      } catch (e) {
        useToast().error("Couldn't create new game, try again in a while");
        return Promise.reject("Couldn't create new game: " + e);
      }
      if (r.error) {
        if (r.status == 409) {
          useToast().success(
            "There's already an ongoing game, finish that first!"
          );
          return Promise.reject("There's already an ongoing game");
        } else {
          useToast().error("Couldn't create new game, try again in a while");
          return Promise.reject("Couldn't create new game: " + r.message);
        }
      } else {
        this.games[r.data.game.id] = r.data.game;
        return r.data.game;
      }
    },
    async abort(gameId: string): Promise<{ gameState: Game }> {
      const r = await api.abortGame(gameId);
      if (r.error) {
        return Promise.reject("Failed to abort: " + r.message);
      } else {
        this.games[gameId] = r.data.game;
        return { gameState: r.data.game };
      }
    },
    async resign(gameId: string): Promise<{ gameState: Game }> {
      const r = await api.resignGame(gameId);
      if (r.error) {
        return Promise.reject("Failed to resign: " + r.message);
      } else {
        this.games[gameId] = r.data.game;
        return { gameState: r.data.game };
      }
    },
    async makeMove(gameId: string, move: Move): Promise<Game> {
      const r = await api.makeMove(gameId, move);
      if (r.error) {
        return Promise.reject("Failed to make move: " + r.message);
      } else {
        this.games[gameId] = r.data.game;
        return r.data.game;
      }
    },
    async makeEngineMove(
      gameId: string
    ): Promise<{ game: Game; engineTakenTime?: number }> {
      const maxRetries = 2;
      let lastError: any;

      for (let attempt = 0; attempt <= maxRetries; attempt++) {
        try {
          const r = await api.makeEngineMove(
            gameId,
            usePageStore().allowResignation
          );
          if (r.error) {
            lastError = new Error("Failed to make move: " + r.message);
          } else {
            return r.data;
          }
        } catch (e) {
          lastError = e;
        }

        // Wait a bit before trying again
        await new Promise((resolve) => setTimeout(resolve, 500));
      }

      // All attempts failed
      useToast().error("Failed to get engine move, try again in a while");
      return Promise.reject("Failed to make move: " + lastError);
    },
    async makeMoveAndMakeEngineMove(
      gameId: string,
      move: Move,
      moveTime: number | null
    ): Promise<{ game: Game; engineTakenTime?: number }> {
      const maxRetries = 2;
      let lastError: any;

      for (let attempt = 0; attempt <= maxRetries; attempt++) {
        try {
          const r = await api.makeMoveAndMakeEngineMove(
            gameId,
            move,
            moveTime,
            usePageStore().allowResignation
          );
          if (r.error) {
            lastError = new Error("Failed to make move: " + r.message);
          } else {
            return r.data;
          }
        } catch (e) {
          lastError = e;
        }

        // Wait a bit before trying again
        await new Promise((resolve) => setTimeout(resolve, 500));
      }

      // All attempts failed
      return Promise.reject("Failed to make move: " + lastError);
    },
  },
  getters: {
    active(): Game | null {
      // This assumes that there's only one active game at a time, if there are more
      // this will just return the first one
      for (const key of Object.keys(this.games)) {
        if (this.games[key].isActive) {
          return this.games[key];
        }
      }
      return null;
    },
    side(): (gameId: string) => Side {
      return (gameId) => {
        const game = this.games[gameId];
        if (game == null || game.moves == null) {
          throw "Game not found";
        }

        return game.userSide;
      };
    },
    moveString(): (gameId: string) => string {
      return (gameId) => {
        const game = this.games[gameId];
        if (game == null || game.moves == null) {
          throw "Game not found";
        } else {
          return game.moves.join(" ");
        }
      };
    },
  },
});

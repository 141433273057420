<template>
  <div style="text-align: center">
    <h1>Page not found</h1>
    <p>Sorry, the page you are looking for does not exist.</p>
    <button
      class="btn btn-info"
      @click="
        router.push({
          name: 'home',
        })
      "
    >
      Go to home
    </button>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";

const router = useRouter();
</script>

<style scoped></style>

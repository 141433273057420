import axios from "axios";
import { useUserStore } from "@/stores/userStore";
import type { Move } from "chess.js";
import {
  Side,
  type AllBotsResponse,
  type GetActiveGamesResponse,
  type GetAllUserGamesResponse,
  type GetBotProfileResponse,
  type GetGameStateResponse,
  type GetUserDataResponse,
  type GameUpdateResponse,
  type NewGameResponse,
  type RequestGameChatResponse,
  type ApiResponse,
  type DetailedFeedback,
  ChatPromptType,
  type GetNextUnbeatenBotResponse,
  type Game,
  type GetUserQuickStatsResponse,
  type GetActiveChallengesResponse,
  type GetChallengeResponse,
  type GetUnbeatenChallengeResponse,
  type ChallengeFromPosition,
  type GetPracticeResponse,
  type GetPracticesResponse,
  type GetPuzzlesResponse,
  type PuzzleSolveHistory,
  type GetUnbeatenPuzzleSetResponse,
  type GetBannerResponse,
  type GetGeneralChatResponse,
  type ChatTriggerId,
  type GetDailyMatchupResponse,
  type GetDailyPositionResponse,
  type TimeControl,
  type GetNextOpponentResponse,
  type GetUserStatsResponse,
  type GetInitialUserDataResponse,
} from "@/types/apitypes";
import { timeControlToString } from "@/util/util";

const rest = axios.create({
  baseURL: import.meta.env.DEV
    ? "http://localhost:8082/api"
    : "https://chessiversebackend-241305620106.europe-west3.run.app/api",
  headers: {
    "ngrok-skip-browser-warning": "true",
  },
});

let isRetrying: string[] = [];

rest.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      !isRetrying.includes(error.config.url)
    ) {
      // Add the url to the retrying list so we don't trigger this code for the retry
      isRetrying.push(error.config.url);

      // Since this was a 401 error, we'll try to refresh the token once and hope that works
      // We do refresh token after a certain time, but this is a fallback
      const idToken = await useUserStore().refreshIdToken(true);
      const originalRequest = error.config;
      originalRequest.headers["Authorization"] = "Bearer " + idToken;

      try {
        const response = await rest(originalRequest);
        // Remove the url from the retrying list
        isRetrying = isRetrying.filter((item) => item !== error.config.url);
        return response;
      } catch (retryError) {
        // Remove the url from the retrying list
        isRetrying = isRetrying.filter((item) => item !== error.config.url);
        return Promise.reject(retryError);
      }
    }

    if (
      error.response &&
      error.response.status === 401 &&
      isRetrying.includes(error.config.url)
    ) {
      useUserStore().logout();
    }

    return Promise.reject(error);
  }
);

async function getUseridOrThrow(): Promise<string> {
  const idToken = await useUserStore().refreshIdToken();

  const firebaseUser = useUserStore().user.firebaseUser;

  if (firebaseUser == null || idToken == null) {
    useUserStore().logout();
    throw "No user logged in";
  }

  // Set the default authorization header to the idToken so it's passed with all requests
  rest.defaults.headers.common["Authorization"] = "Bearer " + idToken;

  return firebaseUser.uid;
}

export const getBots = async (): Promise<AllBotsResponse> => {
  let userId;

  try {
    userId = await getUseridOrThrow();
  } catch (e) {
    console.error("Problem when getting user id: " + e);
  }
  if (userId === undefined) {
    return (await rest.get("/bots/list")).data;
  } else {
    return (await rest.get("/users/" + userId + "/bots/list")).data;
  }
};

export const getBotsFromDate = async (
  date: string
): Promise<AllBotsResponse> => {
  return (
    await rest.get(
      "/users/" + (await getUseridOrThrow()) + "/bots/list/" + date
    )
  ).data;
};

export async function setInitialRating(
  initialRating: "beginner" | "intermediate" | "advanced"
): Promise<ApiResponse> {
  return await rest.post(
    "/users/" + (await getUseridOrThrow()) + "/initialrating/" + initialRating
  );
}

export const getInitialUserProfile = async (
  userId: string,
  idToken: string
): Promise<GetInitialUserDataResponse> => {
  rest.defaults.headers.common["Authorization"] = "Bearer " + idToken;

  // Used when know the userId, like when in the middle of signing in
  return (await rest.get("/users/" + userId + "/initial")).data;
};

export const getUserProfile = async (): Promise<GetUserDataResponse> => {
  return (await rest.get("/users/" + (await getUseridOrThrow()))).data;
};

export const setNewsletterConsentAccepted = async (): Promise<ApiResponse> => {
  return await rest.post(
    "/users/" + (await getUseridOrThrow()) + "/acceptnews"
  );
};

export const getStats = async (
  userId: string | undefined = undefined
): Promise<GetUserStatsResponse> => {
  userId = userId ?? (await getUseridOrThrow());

  return (await rest.get(`/users/${userId}/stats`)).data;
};

export const getQuickStats = async (
  userId: string | undefined = undefined
): Promise<GetUserQuickStatsResponse> => {
  userId = userId ?? (await getUseridOrThrow());
  return (await rest.get(`/users/${userId}/quickstats`)).data;
};

export const getActiveChallenges =
  async (): Promise<GetActiveChallengesResponse> => {
    return (
      await rest.get(
        "/users/" + (await getUseridOrThrow()) + "/challenges/active"
      )
    ).data;
  };

export const getChallenge = async (
  challengeId: string
): Promise<GetChallengeResponse> => {
  return (
    await rest.get(
      "/users/" + (await getUseridOrThrow()) + "/challenges/" + challengeId
    )
  ).data;
};

export const getPractice = async (
  practiceId: string
): Promise<GetPracticeResponse> => {
  return (
    await rest.get(
      "/users/" + (await getUseridOrThrow()) + "/practices/" + practiceId
    )
  ).data;
};

export const getPractices = async (): Promise<GetPracticesResponse> => {
  return (await rest.get("/users/" + (await getUseridOrThrow()) + "/practices"))
    .data;
};

export const solvePuzzle = async (
  botId: string,
  puzzleId: string,
  puzzleSolveHistory: PuzzleSolveHistory
): Promise<GetPuzzlesResponse> => {
  return (
    await rest.post(
      "/users/" + (await getUseridOrThrow()) + "/puzzleset/" + botId,
      { puzzleId: puzzleId, puzzleSolveHistory: puzzleSolveHistory }
    )
  ).data;
};

export const getPuzzleSet = async (
  botId: string
): Promise<GetPuzzlesResponse> => {
  const response = await rest.get(
    "/users/" + (await getUseridOrThrow()) + "/puzzleset/" + botId
  );

  // Puzzle responses are base64-encoded (for some simple obfuscating), just need to decode here
  const decodedResponse = atob(response.data);
  return JSON.parse(decodedResponse);
};

export const getPuzzles = async (): Promise<GetPuzzlesResponse> => {
  const response = await rest.get(
    "/users/" + (await getUseridOrThrow()) + "/puzzles"
  );

  // Puzzle responses are base64-encoded (for some simple obfuscating), just need to decode here
  const decodedResponse = atob(response.data);
  return JSON.parse(decodedResponse);
};

export const newChallengeGame = async (
  challengeId: string,
  difficulty: string,
  timeControl: TimeControl | null | undefined
): Promise<NewGameResponse> => {
  const params = {
    ...(timeControl ? { timecontrol: timeControlToString(timeControl) } : {}),
  };
  const url = `/users/${await getUseridOrThrow()}/games/new/challenges/${challengeId}/difficulty/${difficulty}`;

  const response = await rest.post(url, {}, { params });
  return response.data;
};

export const newPracticeGame = async (
  practiceId: string,
  botId: string,
  timeControl: TimeControl | null | undefined
): Promise<NewGameResponse> => {
  const params = {
    ...(timeControl ? { timecontrol: timeControlToString(timeControl) } : {}),
  };
  const url = `/users/${await getUseridOrThrow()}/games/new/practices/${practiceId}/bots/${botId}`;

  const response = await rest.post(url, {}, { params });
  return response.data;
};

export const newDailyPositionGame = async (
  type: "dailyendgame" | "dailymaster",
  difficulty: "simple" | "easy" | "balanced" | "hard" | "intense",
  timeControl: TimeControl | null | undefined
): Promise<NewGameResponse> => {
  const params = {
    ...(timeControl ? { timecontrol: timeControlToString(timeControl) } : {}),
  };
  const url = `/users/${await getUseridOrThrow()}/games/new/challenges/dailyposition/${type}/difficulty/${difficulty}`;

  const response = await rest.post(url, {}, { params });
  return response.data;
};

export const newDailyMatchupGame = async (
  timeControl: TimeControl | null | undefined
): Promise<NewGameResponse> => {
  const params = {
    ...(timeControl ? { timecontrol: timeControlToString(timeControl) } : {}),
  };
  const url = `/users/${await getUseridOrThrow()}/games/new/challenges/dailymatchup`;

  const response = await rest.post(url, {}, { params });
  return response.data;
};

export const newCustomChallengeGame = async (
  customChallenge: ChallengeFromPosition,
  timeControl: TimeControl | null | undefined
): Promise<NewGameResponse> => {
  const params = {
    ...(timeControl ? { timecontrol: timeControlToString(timeControl) } : {}),
  };
  const url = `/users/${await getUseridOrThrow()}/games/new/challenges/custom`;

  const response = await rest.post(url, customChallenge, { params });
  return response.data;
};

export const newGameCasual = async (
  botId: String,
  side: Side,
  timeControl: TimeControl | null | undefined
): Promise<NewGameResponse> => {
  const params = {
    botid: botId,
    side: side == Side.White ? "white" : "black",
    ...(timeControl ? { timecontrol: timeControlToString(timeControl) } : {}),
  };
  const url = `/users/${await getUseridOrThrow()}/games/new/casual`;

  const response = await rest.post(url, {}, { params });
  return response.data;
};

export const newGameRated = async (
  botid: string | undefined,
  timeControl: TimeControl | null | undefined
): Promise<NewGameResponse> => {
  const params = {
    ...(botid ? { botid } : {}),
    ...(timeControl ? { timecontrol: timeControlToString(timeControl) } : {}),
  };
  const url = `/users/${await getUseridOrThrow()}/games/new/rated`;

  const response = await rest.post(url, {}, { params });
  return response.data;
};

export const getActiveUserGames = async (): Promise<GetActiveGamesResponse> => {
  return (
    await rest.get("/users/" + (await getUseridOrThrow()) + "/games/active")
  ).data;
};

export const getDailyPosition = async (
  type: "dailyendgame" | "dailymaster"
): Promise<GetDailyPositionResponse> => {
  return (
    await rest.post(
      "/users/" + (await getUseridOrThrow()) + "/dailyposition/" + type
    )
  ).data;
};

export const getDailymatchup = async (): Promise<GetDailyMatchupResponse> => {
  return (
    await rest.post("/users/" + (await getUseridOrThrow()) + "/dailymatchup")
  ).data;
};

export const getAllUserGames = async (
  fromDate: Date,
  count: number
): Promise<GetAllUserGamesResponse> => {
  function mapJsonToGame(json: any): Game {
    return {
      ...json,
      startedAt: convertToJSDate(json.startedAt),
      endedAt: json.endedAt ? convertToJSDate(json.endedAt) : undefined,
    };
  }

  function convertToJSDate(timestamp: {
    _seconds: number;
    _nanoseconds: number;
  }): Date {
    const milliseconds =
      timestamp._seconds * 1000 + timestamp._nanoseconds / 1_000_000;
    return new Date(milliseconds);
  }

  const response = await rest.get(
    "/users/" +
      (await getUseridOrThrow()) +
      "/games?fromDate=" +
      fromDate +
      "&count=" +
      count
  );
  return {
    ...response.data,
    data: {
      games: response.data.data.games.map(mapJsonToGame),
    },
  };
};

export const getUnbeatenBots =
  async (): Promise<GetNextUnbeatenBotResponse> => {
    return (
      await rest.get(
        "/users/" + (await getUseridOrThrow()) + "/bots/nextunbeaten"
      )
    ).data;
  };

export const getDashboardBanner = async (): Promise<GetBannerResponse> => {
  return (await rest.get("/users/" + (await getUseridOrThrow()) + "/banners/"))
    .data;
};

export const getNextOpponentSuggestion = async (
  gameId: string | null
): Promise<GetNextOpponentResponse> => {
  let url = "/users/" + (await getUseridOrThrow()) + "/nextopponent";

  if (gameId != null) {
    url += "?gameid=" + gameId;
  }

  return (await rest.get(url)).data;
};

export const getNextUnfinishedPuzzleSet = async (
  currentPuzzleSetId: string
): Promise<GetUnbeatenPuzzleSetResponse> => {
  return (
    await rest.get(
      "/users/" +
        (await getUseridOrThrow()) +
        "/puzzlesets/nextunbeaten?current=" +
        currentPuzzleSetId
    )
  ).data;
};

export const getNextUnbeatenChallenge =
  async (): Promise<GetUnbeatenChallengeResponse> => {
    return (
      await rest.get(
        "/users/" + (await getUseridOrThrow()) + "/challenges/nextunbeaten"
      )
    ).data;
  };

export const sendFeedback = async (feedback: string): Promise<ApiResponse> => {
  return (
    await rest.post("/users/" + (await getUseridOrThrow()) + "/feedback", {
      feedback: feedback,
    })
  ).data;
};

export const sendGameFeedback = async (
  gameId: string,
  feedback: DetailedFeedback
): Promise<ApiResponse> => {
  return (
    await rest.post(
      "/users/" + (await getUseridOrThrow()) + "/games/" + gameId + "/feedback",
      {
        feedback: feedback,
      }
    )
  ).data;
};

export const getBotProfile = async (
  botId: String,
  anonymous: boolean = false
): Promise<GetBotProfileResponse> => {
  function mapJsonToGame(json: any): Game {
    return {
      ...json,
      startedAt: convertToJSDate(json.startedAt),
      endedAt: json.endedAt ? convertToJSDate(json.endedAt) : undefined,
    };
  }

  function convertToJSDate(timestamp: {
    _seconds: number;
    _nanoseconds: number;
  }): Date {
    const milliseconds =
      timestamp._seconds * 1000 + timestamp._nanoseconds / 1_000_000;
    return new Date(milliseconds);
  }

  if (anonymous) {
    return (await rest.get("/bots/" + botId)).data;
  } else {
    const response = await rest.get(
      "/users/" + (await getUseridOrThrow()) + "/bots/" + botId + "/profile"
    );

    const adjustedResponse = response.data;

    adjustedResponse.data.gameStats.latest =
      adjustedResponse.data.gameStats.latest.map(mapJsonToGame);
    return adjustedResponse;
  }
};

export const getGameState = async (
  gameId: string
): Promise<GetGameStateResponse> => {
  return (
    await rest.get("/users/" + (await getUseridOrThrow()) + "/games/" + gameId)
  ).data;
};

export const abortGame = async (
  gameId: String
): Promise<GameUpdateResponse> => {
  return (
    await rest.post(
      "/users/" + (await getUseridOrThrow()) + "/games/" + gameId + "/abort"
    )
  ).data;
};

export const resignGame = async (
  gameId: String
): Promise<GameUpdateResponse> => {
  return (
    await rest.post(
      "/users/" + (await getUseridOrThrow()) + "/games/" + gameId + "/resign"
    )
  ).data;
};

export const makeMoveAndMakeEngineMove = async (
  gameId: String,
  move: Move,
  moveTime: number | null,
  allowResign: boolean = true
): Promise<GameUpdateResponse> => {
  return (
    await rest.post(
      "/users/" +
        (await getUseridOrThrow()) +
        "/games/" +
        gameId +
        "/move/" +
        move.lan +
        "/respond?allowResign=" +
        allowResign,
      { moveTime: moveTime }
    )
  ).data;
};

export const makeMove = async (
  gameId: String,
  move: Move
): Promise<GameUpdateResponse> => {
  return (
    await rest.post(
      "/users/" +
        (await getUseridOrThrow()) +
        "/games/" +
        gameId +
        "/move/" +
        move.lan
    )
  ).data;
};

export const makeEngineMove = async (
  gameId: String,
  allowResign: boolean = true
): Promise<GameUpdateResponse> => {
  return (
    await rest.post(
      "/users/" +
        (await getUseridOrThrow()) +
        "/games/" +
        gameId +
        "/move/opponent?allowResign=" +
        allowResign
    )
  ).data;
};

export async function getChat(
  botId: string,
  triggerId: ChatTriggerId,
  triggerData: Record<string, string>,
  anonymous: boolean = false,
  fresh: boolean = false // New parameter for polling
): Promise<GetGeneralChatResponse> {
  const timeoutPromise = new Promise((_, reject) => {
    setTimeout(() => reject(new Error("Request timed out")), 10000); // 10 seconds timeout for the request
  });

  return Promise.race([
    (async () => {
      const queryParam = fresh ? "?fresh=true" : "";
      const url = anonymous
        ? `/bots/${botId}/triggerid/${triggerId}${queryParam}`
        : `/users/${await getUseridOrThrow()}/bots/${botId}/triggerid/${triggerId}${queryParam}`;

      return (await rest.post(url, { triggerData: triggerData })).data;
    })(),
    timeoutPromise,
  ]);
}

export async function getOldChat(
  botId: string,
  triggerId: ChatTriggerId,
  triggerData: Record<string, string>,
  anonymous: boolean = false
): Promise<GetGeneralChatResponse> {
  const timeoutPromise = new Promise((_, reject) => {
    setTimeout(() => reject(new Error("Request timed out")), 10000); // 10 seconds
  });

  return Promise.race([
    (async () => {
      if (anonymous) {
        return (
          await rest.post("/bots/" + botId + "/triggerid/" + triggerId, {
            triggerData: triggerData,
          })
        ).data;
      } else {
        return (
          await rest.post(
            "/users/" +
              (await getUseridOrThrow()) +
              "/bots/" +
              botId +
              "/triggerid/" +
              triggerId,
            { triggerData: triggerData }
          )
        ).data;
      }
    })(),
    timeoutPromise,
  ]);
}

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootswatch/dist/sketchy/bootstrap.min.css";
import "bootstrap";
import "@/assets/main.css";
import { createPersistedState } from "pinia-plugin-persistedstate";
import toast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import * as Sentry from "@sentry/vue";
import { useUserStore } from "@/stores/userStore";
import { plugin as VueTippy } from "vue-tippy";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/perspective.css";
import VueApexCharts from "vue3-apexcharts";

const pinia = createPinia();
const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://3e25929acf22af2a077c81ab8ccc6587@o4507182935769088.ingest.de.sentry.io/4507182940029008",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/chessiverse\.com\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

pinia.use(createPersistedState());

app.use(VueApexCharts);
app.use(pinia);
app.use(router);
app.use(toast);
app.use(
  VueTippy,
  // optional
  {
    directive: "tippy", // => v-tippy
    defaultProps: {
      animation: "perspective",
      placement: "top",
      allowHTML: true,
      delay: [500, 0],
    },
  }
);

useUserStore().setRouter(router);

app.mount("#app");

<template>
  <div
    class="card"
    style="
      width: 9.5rem;
      padding: 1rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      filter: drop-shadow(2px 2px 2px var(--clr-main-lighter));
    "
  >
    <h3
      style="
        height: 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      {{ heading }}
    </h3>
    <span style="font-size: 1.2rem">{{ loading ? "-" : subHeading }}</span>
    <div
      v-if="!loading"
      style="
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        flex-direction: column;
      "
    >
      <span
        v-if="styleName == 'check'"
        style="color: var(--clr-accent4); font-size: 4.5rem"
        ><i class="check-icon fa-solid fa-circle-check"
      /></span>
      <img
        v-else-if="styleName == 'star'"
        style="width: 4.5rem; height: 4.5rem"
        src="https://storage.googleapis.com/chessiverse1/images/elements/star.webp"
      />
      <div v-else>
        <img
          style="width: 4.5rem; height: 4.5rem"
          :src="imageOverride != null ? imageOverride : style.img"
        />
        <span
          v-if="imageOverride == null"
          class="card"
          :style="{ padding: '0 0.5rem', backgroundColor: style.bg }"
          >{{ style.name }}</span
        >
      </div>
    </div>
    <div
      v-else
      style="
        width: 4.5rem;
        height: 4.5rem;
        margin-bottom: 2rem;
        border-radius: 50%;
        border: 2px solid var(--clr-main-lightest);
      "
    ></div>
    <span style="white-space: nowrap">{{ loading ? "-" : footer }}</span>
  </div>
</template>
<script setup lang="ts">
import { personaCategories } from "@/util/personplaycategory";
import { ref, watch } from "vue";

const props = defineProps({
  heading: {
    type: String,
    required: true,
  },
  subHeading: {
    type: [String, Number],
    required: true,
  },
  styleName: {
    type: String,
  },
  imageOverride: {
    type: String,
  },
  footer: {
    type: String,
  },
  loading: {
    type: Boolean,
    default: true,
  },
});

let style = ref();

watch(
  () => [props.loading, props.styleName],
  () => {
    style.value = personaCategories().find(
      (p) => p.name.toLowerCase() === props.styleName?.toLowerCase()
    );
  },
  { immediate: true }
);

watch(
  () => props.loading,
  () => {
    style.value = personaCategories().find(
      (p) => p.name.toLowerCase() === props.styleName?.toLowerCase()
    );
  },
  { immediate: true }
);
</script>
<style scoped></style>
